<template>
  <header>
    <!-- <a @click="openNav" class="logo menu"
      ><i class="material-icons-outlined menu">menu</i></a
    > -->

    <a to="/" class="logo space">Shopper</a>
    <ul>
      <li><router-link class="item" to="/">Home</router-link></li>
      <li><router-link class="item" to="/products">Products</router-link></li>
      <li><router-link class="item" to="/orders">Orders</router-link></li>
      <li><router-link class="item" to="/search">Search</router-link></li>
      <li><router-link class="item" to="/account">Account</router-link></li>
      <li>
        <router-link class="item" to="/cart"
          ><i class="material-icons-outlined size-24"
            >shopping_cart</i
          ></router-link
        >
      </li>
    </ul>
    <router-link to="/account" class="link"
      ><i class="material-icons-outlined menu right">person</i></router-link
    >
  </header>
</template>

<script>
export default {};
</script>

<style scoped>
header {
  width: 100%;
  height: 50px;
  padding: 0;
  background-color: #fff !important;
  box-shadow: 1px 1px 1px rgba(78, 56, 56, 0.2) !important;
  position: fixed;
  top: 0;
  margin: 0;
  z-index: 99;
}
.logo {
  float: left;
  display: block;
  color: black;
  text-align: center;
  padding: 16px 16px;
  text-decoration: none;
}
.menu {
  display: none;
}
.right {
  float: right;
}
.size-24 {
  font-size: 18px;
  padding: 0 0;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  float: right;
  overflow: hidden;
}

ul li {
  float: left;
}
.link {
  display: none;
}
li .item {
  display: block;
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

li .item:hover {
  background-color: rgb(226, 226, 226);
}
@media only screen and (min-width: 576px) {
  .router-link-exact-active {
    color: #765cd1;
  }
  .router-link-active {
    color: #765cd1;
  }
}

/*  */
@media only screen and (max-width: 576px) {
  header {
    width: 100%;
    height: 50px;
    padding: 0;
    background-color: #fff !important;
    box-shadow: 1px 1px 1px rgba(78, 56, 56, 0.2) !important;
    position: fixed;
    top: 0;
    margin: 0;
    z-index: 99;
  }
  ul {
    display: none;
  }
  .menu {
    display: block;
  }

  .link {
    display: block;
    color: black;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
  }
  .link .router-link-active {
    background-color: #fff;
  }
}
</style>
