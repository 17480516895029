const state = () => {
  return {
    user: {
      _id: "",
      userAvatar: "",
      name: "",
    },
    authState: false,
    tokens: {
      accessToken: "",
      refreshToken: "",
    },
    loading: false,
    notification: {
      message: "",
      type: "",
      state: false,
    },
  };
};

const getters = {
  getAuthstate(state) {
    return state.authState;
  },
  getTokens(state) {
    return state.tokens;
  },
  getUser(state) {
    return state.user;
  },
  getLoading(state) {
    return state.loading;
  },
  getNotification(state) {
    return state.notification;
  },
};

const mutations = {};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
