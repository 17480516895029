<template>
  <nav class="nav--bottom">
    <router-link to="/" class="nav___link">
      <i class="material-icons-outlined nav___icon">home</i>
      <!-- <span class="nav___text">Home</span> -->
    </router-link>
    <router-link to="/products" class="nav___link">
      <i class="material-icons-outlined nav___icon">shopping_basket</i>
      <!-- <span class="nav___text">About</span> -->
    </router-link>
    <router-link to="/search" class="nav___link">
      <i class="material-icons-outlined nav___icon">search</i>
      <!-- <span class="nav___text">Settings</span> -->
    </router-link>
    <router-link to="/orders" class="nav___link">
      <i class="material-icons-outlined nav___icon">local_shipping</i>
      <!-- <span class="nav___text">Contact</span> -->
    </router-link>
    <router-link to="/cart" class="nav___link">
      <i class="material-icons-outlined nav___icon">shopping_cart</i>
      <!-- <span class="nav___text">Settings</span> -->
    </router-link>
  </nav>
</template>

<script>
export default {};
</script>

<style scoped>
@media only screen and (min-width: 576px) {
  .nav--bottom {
    display: none;
  }
}
@media only screen and (max-width: 576px) {
  .nav--bottom {
    position: fixed;
    height: 50px;
    bottom: 0;
    width: 100%;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
  }

  .nav___link {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center !important;
    justify-content: center;
    flex-grow: 1;
    min-width: 50px;
    overflow: hidden;
    white-space: nowrap;
    font-size: 12px;
    color: #444444;
    text-decoration: none !important;
    -webkit-tap-highlight-color: transparent;
    transition: background-color 0.1s ease-in-out;
  }

  .nav___link:hover {
    background-color: #f5f5f5;
  }

  .nav___icon {
    font-size: 22px;
    justify-content: center !important;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  .font-36 {
    font-size: 30px;
  }
  .font-24 {
    font-size: 26px;
  }
  .router-link-exact-active {
    color: #765cd1;
  }
}
</style>
