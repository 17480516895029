import axios from "../../axios";
const state = () => {
  return {
    products: [],
    featured: [],
    pagination: {
      count: null,
      limit: null,
      pages: null,
      pagger: {
        next: null,
        prev: null,
      },
    },
    cartItems: [],
    cartItemsCount: 0,
    orders: [],
    loading: false,
    notification: {
      message: "",
      type: "",
      state: false,
    },
  };
};

const getters = {
  getFeaturedProducts(state) {
    return state.featured;
  },
  getProducts(state) {
    return state.products;
  },
  getPagination(state) {
    return state.pagination;
  },
  getOrders(state) {
    return state.orders;
  },
  getLoading(state) {
    return state.loading;
  },
  getNotification(state) {
    return state.notification;
  },
  getCartItems(state) {
    return state.cartItems;
  },
};

const mutations = {
  setLoading(state, payload) {
    state.loading = payload;
  },
  setProducts(state, payload) {
    state.products = payload;
  },
  setFeatured(state, payload) {
    state.featured = payload;
  },
  setPagination(state, payload) {
    state.pagination = payload;
  },
  addToCart(state, payload) {
    if (payload.quantity < 1) return;
    let cartItem = payload;
    cartItem = { ...cartItem, quantity: 1 };
    if (state.cartItems.length > 0) {
      const bool = state.cartItems.some((i) => i._id === cartItem._id);
      if (bool) {
        const itemIndex = state.cartItems.findIndex(
          (e) => e._id === cartItem._id
        );
        state.cartItems[itemIndex].quantity += 1;
      } else {
        state.cartItems.push(cartItem);
      }
    } else {
      state.cartItems.push(cartItem);
    }
    state.cartItemsCount++;
  },
  removeFromCart(state, payload) {
    if (state.cartItems.length > 0) {
      const bool = state.cartItems.some((i) => i._id === payload._id);
      if (bool) {
        const itemIndex = state.cartItems.findIndex(
          (e) => e._id === payload._id
        );
        if (state.cartItems[itemIndex].quantity !== 0) {
          state.cartItems[itemIndex].quantity -= 1;
          state.cartItemsCount--;
        }
        if (state.cartItems[itemIndex].quantity === 0) {
          state.cartItems.splice(itemIndex, 1);
        }
      }
    }
  },
};

const actions = {
  async loadProducts({ commit }) {
    commit("setLoading", true);
    try {
      const {
        data: { doc, metadata },
      } = await axios({
        url: "/products/all",
        method: "GET",
      });
      commit("setLoading", true);
      commit("setProducts", doc);
      commit("setPagination", metadata);
    } catch (error) {
      console.log(error);
      commit("setLoading", false);
    }
  },
  async loadFeaturedProducts({ commit }) {
    commit("setLoading", true);
    try {
      const {
        data: { doc },
      } = await axios({
        url: "/products/featured",
        method: "GET",
      });
      commit("setLoading", true);
      commit("setFeatured", doc);
    } catch (error) {
      console.log(error);
      commit("setLoading", false);
    }
  },
  setLoading({ commit }, payload) {
    commit("setLoading", payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
