<template>
  <top-navigation></top-navigation>
  <router-view v-slot="{ Component }">
    <transition name="fadeIn">
      <component :is="Component" />
    </transition>
  </router-view>
  <bottom-navigation></bottom-navigation>
</template>
<script>
import TopNavigation from "./components/navigation/TopNavigation.vue";
import BottomNavigation from "./components/navigation/BottomNavigation.vue";
export default {
  components: {
    BottomNavigation,
    TopNavigation,
  },
  setup() {},
};
</script>
<style>
html {
  scroll-behavior: smooth;
}
.container {
  margin: 0 auto;
  max-width: 1280px;
  width: 90% !important;
}
@media only screen and (max-width: 601px) {
  .container {
    width: 95% !important;
  }
}
@media only screen and (min-width: 993px) {
  .container {
    width: 85% !important;
  }
}
@import "https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp";

@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/Raleway-VariableFont_wght.ttf");
}

* {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.fadeIn-enter-active {
  animation: fadeIn 0.6s ease-out;
}

.fadeIn-leave-active {
  animation: fadeOut 0.2s ease-in delay 0.2s;
}
@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}
</style>
