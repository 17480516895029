<template>
  <div class="home">
    <div class="container">
      <h5>Home</h5>
      <div class="container">
        <Carousel :autoplay="5000" :wrap-around="true" :items-to-show="3">
          <Slide v-for="slide in 10" :key="slide">
            <div class="carousel__item">{{ slide }}</div>
          </Slide>

          <template #addons>
            <Navigation />
          </template>
        </Carousel>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { computed, reactive } from "vue";
import { useHead } from "@vueuse/head";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";

export default {
  name: "Home",
  components: {
    Carousel,
    Slide,
  },
  setup() {
    const siteData = reactive({
      title: `E commerce | Home`,
      description: `My beautiful website`,
    });
    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description),
        },
      ],
    });
  },
};
</script>
<style>
.home {
  min-height: 100vh;
  margin-top: 55px;
  margin-bottom: 55px;
}
</style>
