import { createStore, createLogger } from "vuex";
import createPersistedState from "vuex-persistedstate";

// modules
import auth from "./auth";
import products from "./products";

export default createStore({
  plugins: [
    createLogger(),
    createPersistedState({
      key: "e commerce",
    }),
  ],
  modules: {
    auth,
    products,
  },
});
